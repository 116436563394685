@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,300,200,100&display=swap');
@import url('https://fonts.googleapis.com/css?family=Inter:400,600,300,200,100&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins:400,600,300,200,100&display=swap');

@import '@inera/ids-design/themes/inera-admin/inera-admin.css';
@import '@inera/ids-design/themes/inera/inera.css';


.container {
    padding-left: 1em;
}

.textarea100 {
    width: 100%;
}

.right {
    text-align: right;
}

.scroll {
    max-height: 400px;
    overflow-y: scroll;
}

.container__graph-area {
    max-height: 400px;
    border: 1px dotted gray;
    margin-left: 20px;
    margin-top: 4px;
    z-index: 1;
    overflow: hidden;
    text-align: center;
}


.d3graph {
    font-family: sans-serif;
    text-align: center;
}

/*
 *  SCROLLBAR STYLE
 */


/* Track */
#scrollbar-style::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border: 1px solid #F5F5F5;
    border-radius: 5px;
    background-color: #F5F5F5;
}

#scrollbar-style::-webkit-scrollbar
{
    width: 12px;
    background-color: #F5F5F5;
}

/* Handle */
#scrollbar-style::-webkit-scrollbar-thumb
{
    border-radius: 5px;
    border: 1px solid #F5F5F5;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #4D7660;
}